"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: '広瀬香美ファンクラブ',
    organizationId: 'k0M9698vA7qZPxiZai5W',
    version: '1.1',
    ionicAppId: '',
    applicationKey: 'kohmihirose',
    applicationType: 'standalone',
    artistId: 'CLslF9OT3lSdczYPsRlqNdc9i012',
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: false,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: false,
            nft: false,
            liveStream: true,
            item: true,
            store: true,
            campaign: false,
            mission: false,
            resale: false,
            preSale: true,
            event: true,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial02.png',
            'images/tutorial/Tutorial04.png',
        ],
        globalTabs: [],
        artistTabs: ['home', 'posts', 'events', 'store', 'myProfile'],
    },
    deeplinkUrl: 'https://kohmi-hirose-premium.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    storagePath: 'kohmihirose',
    dynamicLinkPrefix: 'kohmihirose',
};
exports.default = appConfig;
